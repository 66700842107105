<template>
  <div>
    <SpinnerLoader v-if="inventoryStatus !== 'success'" :loading="inventoryStatus" />
    <template v-else-if="inventoryStatus === 'success' && inventory">
      <div class="table-responsive mb-0 inventory">
        <table class="table table-sm small table-hover table-bordered ws-nowrap sticky-headers sticky-footer">
          <thead>
            <tr>
              <th rowspan="3" style="min-width: 80px">
                <button class="btn-link btn-transparent mr-1" :title="$t('reports.expandOrCollapseRows')" @click="toggleAllRowsVisibility()">
                  <b-icon :icon="expandAllRows ? 'chevron-bar-contract' : 'chevron-expand'"></b-icon>
                </button>
                {{ $t('table.date') }}
              </th>
              <th rowspan="3" style="min-width: 85px">{{ $t('table.time') }}</th>
              <th colspan="3">{{ $t('table.seconds') }}</th>
              <th colspan="9">wGRP</th>
              <th v-if="!isCurrentUserChannelManager" colspan="3">{{ $t('table.budget') }}</th>
            </tr>
            <tr>
              <th>{{ $t('table.open') }}</th>
              <th>{{ $t('reports.soldOut') }}</th>
              <th>so%</th>

              <th colspan="3">{{ $t('table.open') }}</th>
              <th colspan="3">{{ $t('reports.soldOut') }}</th>
              <th colspan="3">so%</th>

              <th v-if="!isCurrentUserChannelManager" colspan="3">{{ $t('reports.soldOut') }}</th>
            </tr>
            <tr>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>

              <th class="text-right">{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}/{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}/{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.plan') }}</th>
              <th class="text-right">{{ $t('table.fact') }}</th>
              <th class="text-right">{{ $t('table.fact') }}/{{ $t('table.plan') }}</th>

              <template v-if="!isCurrentUserChannelManager">
                <th class="text-right">{{ $t('table.plan') }}</th>
                <th class="text-right">{{ $t('table.fact') }}</th>
                <th class="text-right">{{ $t('table.fact') }}/{{ $t('table.plan') }}</th>
              </template>
            </tr>
          </thead>
          <tbody>
            <template v-for="(group, gpIndex) in inventory.items">
              <tr :key="gpIndex + '_0'" class="bg-light">
                <th colspan="2" class="cursor-pointer" @click="toggleGroupRowsVisibility(group)">
                  <b-icon :icon="group.visible ? 'chevron-up' : 'chevron-down'" :title="$t('reports.expandOrCollapseRow')"></b-icon>
                  {{ group.date }}
                </th>
                <th class="text-right">{{ group.seconds_open_fact | toFixedAndSpace(0) }}</th>
                <th class="text-right">{{ group.seconds_sold_fact | toFixedAndSpace(0) }}</th>
                <th class="text-right">{{ group.seconds_so_fact | toFixedAndSpace }}</th>

                <th class="text-right">{{ group.wgrp_open_plan | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_open_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_open_plan_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_sold_plan | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_sold_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_sold_plan_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_so_plan | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_so_fact | toFixedAndSpace }}</th>
                <th class="text-right">{{ group.wgrp_so_plan_fact | toFixedAndSpace }}</th>

                <template v-if="!isCurrentUserChannelManager">
                  <th class="text-right">{{ group.budget_sold_plan | toFixedAndSpace }}</th>
                  <th class="text-right">{{ group.budget_sold_fact | toFixedAndSpace }}</th>
                  <th class="text-right">{{ group.budget_sold_plan_fact | toFixedAndSpace }}</th>
                </template>
              </tr>
              <template v-if="group.visible">
                <tr v-for="(row, rIndex) in group.inventories" :key="gpIndex + '_1_' + rIndex">
                  <td></td>
                  <td>{{ row.time }}</td>
                  <td class="text-right">{{ row.seconds_open_fact | toFixedAndSpace(0) }}</td>
                  <td class="text-right">{{ row.seconds_sold_fact | toFixedAndSpace(0) }}</td>
                  <td class="text-right">{{ row.seconds_so_fact | toFixedAndSpace }}</td>

                  <td class="text-right">{{ row.wgrp_open_plan | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_open_fact | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_open_plan_fact | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_sold_plan | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_sold_fact | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_sold_plan_fact | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_so_plan | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_so_fact | toFixedAndSpace }}</td>
                  <td class="text-right">{{ row.wgrp_so_plan_fact | toFixedAndSpace }}</td>

                  <template v-if="!isCurrentUserChannelManager">
                    <td class="text-right">{{ row.budget_sold_plan | toFixedAndSpace }}</td>
                    <td class="text-right">{{ row.budget_sold_fact | toFixedAndSpace }}</td>
                    <td class="text-right">{{ row.budget_sold_plan_fact | toFixedAndSpace }}</td>
                  </template>
                </tr>
              </template>
            </template>
          </tbody>

          <tfoot v-if="inventory.totals" class="font-weight-bold">
            <tr>
              <td colspan="2">{{ $t('booking.total') }}</td>
              <td class="text-right">{{ inventory.totals.seconds_open_fact | toFixedAndSpace(0) }}</td>
              <td class="text-right">{{ inventory.totals.seconds_sold_fact | toFixedAndSpace(0) }}</td>
              <td class="text-right">{{ inventory.totals.seconds_so_fact | toFixedAndSpace }}</td>

              <td class="text-right">{{ inventory.totals.wgrp_open_plan | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_open_fact | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_open_plan_fact | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_sold_plan | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_sold_fact | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_sold_plan_fact | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_so_plan | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_so_fact | toFixedAndSpace }}</td>
              <td class="text-right">{{ inventory.totals.wgrp_so_plan_fact | toFixedAndSpace }}</td>

              <template v-if="!isCurrentUserChannelManager">
                <td class="text-right">{{ inventory.totals.budget_sold_plan | toFixedAndSpace }}</td>
                <td class="text-right">{{ inventory.totals.budget_sold_fact | toFixedAndSpace }}</td>
                <td class="text-right">{{ inventory.totals.budget_sold_plan_fact | toFixedAndSpace }}</td>
              </template>
            </tr>
          </tfoot>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerLoader from '@/components/SpinnerLoader';
import toFixedAndSpace from '@/filters/toFixedAndSpace';
import convertDate from '@/filters/convertDate';
import { BIconChevronExpand, BIconChevronBarContract } from 'bootstrap-vue';

export default {
  name: 'InventoryTable',
  // eslint-disable-next-line vue/no-unused-components
  components: { SpinnerLoader, BIconChevronExpand, BIconChevronBarContract },
  filters: { toFixedAndSpace, convertDate },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      isLocale: 'isLocale',
      isThemeHeader: 'isTheme',
      inventory: 'getReportInventory',
      inventoryStatus: 'getReportInventoryStatus',
      expandAllRows: 'getExpandAllRows',
      isCurrentUserChannelManager: 'getIsCurrentUserChannelManager',
    }),
  },
  destroyed() {
    this.$store.commit('clearReportInventory');
  },
  methods: {
    toggleGroupRowsVisibility(group) {
      group.visible = !group.visible;
    },
    toggleAllRowsVisibility() {
      this.$store.commit('toggleExpandAllTableRows');
      for (let group in this.inventory.items) {
        this.inventory.items[group].visible = this.expandAllRows;
      }
    },
  },
};
</script>

<style lang="sass">
.reports-page div.table-responsive.inventory
  height: calc(100vh - 210px)

@media (min-width: 2600px)
  .reports-page div.table-responsive.inventory
    height: calc(100vh - 154px)
</style>
